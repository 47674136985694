
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from '../../../helpers/fakebackend/auth-header';

import {
  required 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Restaurant Category",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      title: "Add Restaurant Category",
      items: [
        {
          text: "Restaurant Categories",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


  
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      alertData:"Category Added Successfully!",
      loading: false,
      restaurants:[],
      categoryArr:[],
      stepNO:"",
      categoryID:"",
      displayName:"",
      restaurantID:"",

      restCategory: [
        {
          id: 1,
          categoryID: "",
          restCategoryID:"",
          stepNO:"",
        },
      ],
    
    };
  },

    validations: {

      restaurantID:{
        required,
    
    },
  },
  methods:{
    onchangeRestaurant(){
      this.axios.post(this.$loggedRole+"/getRestaurantCategoryByRestID",
          {'restID':this.restaurantID.restID})
          .then((result)=>{

          var catArr = result.data.data;
          if(catArr.length>0){
            this.restCategory = [];
              for(var i=0; i < catArr.length;i++)
                {
                    this.restCategory.push({
                      categoryID: catArr[i].categoryArr,
                      displayName: catArr[i].displayName,
                      stepNO: catArr[i].stepNO,
                      restCategoryID: catArr[i].restCategoryID,
                    }); 
                }
          }else{
               this.restCategory = [{
                id: 1,
                categoryID: "",
                restCategoryID:"",
                stepNO:"",
              }];
          }
              
        });
    },
    submitForm(e){
      if(this.restCategory[0].categoryID==""){
        alert('Please Select Category');
        return;
      }
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
          let formData = new FormData();  
            
            formData.append('restCatArr',JSON.stringify(this.restCategory));
            formData.append('restID', this.restaurantID.restID);
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('loginTypeID',this.$storageData.login_type);
    
      e.preventDefault();
      this.axios.post(this.$loggedRole+"/addRestaurantCategory",  formData, {
          headers: authHeader()
        })
      .then((result)=>{
        this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                  this.$router.push({name: 'restaurantCategoryTable'}); 
              },2000);
        
         // console.warn(result);

      })
     .catch(function (error) {
       this.loading = false;
                        console.error(error.response);
                        alert("Something went Wrong");
                    });
      }  
    },
        getRestaurantList(){
         this.axios.post(this.$loggedRole+"/getRestaurantList",
          {'restID':this.$storageData.profile.restID,'restBranchID':this.$storageData.profile.restBranchID,'empID':this.$storageData.profile.pid,'empTypeID':this.$storageData.profile.empTypeID,'city':this.$storageData.profile.city})
          .then((result)=>{
            this.restaurants = result.data.data;
              
        });
    },
    getCategories(){
         this.axios.get(this.$loggedRole+"/getAllCategories"
         ) 
          .then((result)=>{
            this.categoryArr = result.data.data;
              
        });
    },
    changeCategory(categoryName,data){
        data.displayName = categoryName;
    },

  
    /**
     * Add the phone number in form
     */
    AddItem() {
      this.restCategory.push({
        categoryID: "",
        displayName: "",
        stepNO:"",
        restCategoryID:"",
      });
      //console.log(this.restCategory);
    },
    /**
     * Delete the row from form
     */
    deleteItem(index,restCategoryID) {
      if(restCategoryID>0){
        if (confirm("Are you sure you want to delete this element?")){
            this.restCategory.splice(index, 1);
             this.axios.post(this.$loggedRole+"/deleteRestCategory",
                  {'restCategoryID':restCategoryID})
                  .then(()=>{
                      this.showDismissibleAlert = true;
                      this.loading = false;
                      this.alertData = "Category Deleted successfully!"

                      
                });
        }
      }else{
          this.restCategory.splice(index, 1);
      }
      
    },
  

  },
mounted(){
  //alert();
    this.getRestaurantList();
    this.getCategories();
  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
      <div class="alertMsg">
     <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        {{alertData}}
    </b-alert>
  </div>
<form @submit.prevent="submitForm" method="post">

        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                
                <div class="col-md-6 mb-3">
                      <label>Choose Restaurant Brand* </label>
                     <multiselect v-model="restaurantID" :options="restaurants" track-by="restID" label="name" 
                     @input="onchangeRestaurant" :class="{
                        'is-invalid': submitted && $v.restaurantID.$error,
                      }" ></multiselect>
                       <div
                      v-if="submitted && $v.restaurantID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurantID.required"
                        >This value is required.</span
                      >
                    </div>

                </div>

                  <div class="inner-repeater mb-4">
                    <div class="inner mb-3">
                    <div class="row">
                        <label class="col-md-4">Category :</label>
                        <label class="col-md-3">Display Name:</label>
                        <label class="col-md-3">Display Sequence:</label>
                    </div>
                     
                      <div
                        v-for="(data, index) in restCategory"
                        :key="data.id"
                        class="inner mb-3 row"
                      >
                        <div class="col-md-4 col-8">
                          <multiselect v-model="data.categoryID" :options="categoryArr" track-by="categoryID" label="categoryName"  
                            @input="changeCategory(data.categoryID.categoryName,data);"></multiselect>
                        </div>
                        <div class="col-md-3 col-8">
                            <input type="text" class="form-control" v-model="data.displayName">
                        </div>
                        <div class="col-md-3 col-8">
                         
                          <input type="number" :min="1" class="form-control" v-model="data.stepNO">
                        
                        </div>
                        <div class="col-lg-2 align-self-center d-grid">
                          <input
                            type="button"
                            class="btn btn-themeBrown btn-block inner"
                            value="Delete"
                            @click="deleteItem(index,data.restCategoryID)"
                          />
                        </div>
                      </div>
                    </div>
                    <input
                      type="button"
                      class="btn btn-themeOrange inner"
                      value="Add new item"
                      @click="AddItem"
                    />
                  </div>

              <div class =" row" style="float:right;">
                    <b-spinner v-if="loading"
                    class="m-2 col-3"
                    variant="primary"
                    role="status"
                  ></b-spinner>
                  <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
              </div>
        </div>
               
            </div>
        </div>

</form>


  

</Layout>
</template>
<style>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


